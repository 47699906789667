a {
  color: #707275;
}
.navLinks_siteNav__2SE5G .navLinks_flyoutContent__1zvMp {
  border: none !important;
}
.navLinks_siteNav__2SE5G .navLinks_flyoutContent__1zvMp a {
  border: none !important;
}
.navLinks_siteNav__2SE5G {
  background: transparent !important;
}
.navLinks_siteNav__2SE5G a,
.navLinks_siteNav__2SE5G .navLinks_flyoutContent__1zvMp {
  border-color: #5850ec;
}
